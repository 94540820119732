<template>
  <div>
    <GlobalForm
      class="global-form"
      :init-data="initData"
      :form-item-list="formItemList"
      :inline="true"
      :labelWidth="40"
      round
      :needBtnLoading="true"
      @handleConfirm="handleConfirm"
      confirmBtnName="查询"
    >
      <el-form-item
        class="el_from select_down"
        label="回收商名称"
        slot="merchantId"
        style="margin: 0"
        label-width="85px"
      >
        <el-select
          filterable
          v-model="merchantId"
          placeholder="请选择回收商名称"
          clearable
        >
          <el-option
            v-for="item in machineType"
            :key="item.merchantId"
            :label="item.merchantName"
            :value="item.merchantId"
          >
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item
        class="el_from"
        label-width="70px"
        label="授信状态"
        slot="operationSelect"
        style="margin: 0"
      >
        <el-select
          v-model="effectiveStatus"
          placeholder="请选择"
          clearable
          filterable
        >
          <el-option
            v-for="item in shanpsSelectList"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          ></el-option>
        </el-select>
      </el-form-item>
      <el-form-item
        label-width="70px"
        class="el_from select_down"
        label="授信比例"
        slot="productId"
        style="margin: 0"
      >
        <el-select
          v-model="financeRatio"
          placeholder="请选择"
          clearable
          filterable
        >
          <el-option
            v-for="item in productList"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          >
          </el-option>
        </el-select>
      </el-form-item>
    </GlobalForm>
  </div>
</template>

<script>
import moment from "moment";
import _api from "@/utils/request";
export default {
  components: {},
  data() {
    return {
      machineType: [],
      merchantId: "",
      paramsExcel: {},
      effectiveStatus: null,
      financeRatio: null,
      rankType: "02",
      queryDate: "",
      dateType: "today",
      handleShow: false,
      isFrozen: true,
      downloadinitData: {
        entryTime: "", //moment().format("YYYY-MM-DD 23:59:59")
      },
      page3: {
        pageNum: 1,
        pageSize: 10,
      },
      redenvedata: [],
      loading3: false,
      btnLoading: false,
      csetionShow: false,
      iid: null,
      initData: {
        entryTime: [
          moment()
            .day(moment().day()-30)
            .format("YYYY-MM-DD 00:00:00"),
          moment().format("YYYY-MM-DD 23:59:59"),
        ]
      },
      createinitData: {
        posterImg: "",
        posterOriginalFile: "",
        posterType: "",
      },
      userAccount: "",
      shanpsSelectList: [
        {
          label: "未生效",
          value: "00",
        },
        {
          label: "生效中",
          value: "01",
        },
        {
          label: "已禁用",
          value: "03",
        },
        {
          label: "已禁用（已过期）",
          value: "04",
        },
      ],
      productList: [
        {
          label: "10",
          value: "10",
        },
        {
          label: "20",
          value: "20",
        },
        {
          label: "30",
          value: "30",
        },
        {
          label: "40",
          value: "40",
        },
        {
          label: "50",
          value: "50",
        },
        {
          label: "60",
          value: "60",
        },
        {
          label: "70",
          value: "70",
        },
      ],
      pickerMinDate: null,
      pickerMaxDate: null,
      day31: 30 * 24 * 3600 * 1000,
      formItemList: [
        { slotName: "merchantId" }, //商家名称
        {
          key: "contactName",
          type: "input",
          labelName: "联系人",
          placeholder: "请输入联系人",
          lableWidth: "60px",
        },
        {
          key: "contactPhone",
          type: "input",
          labelName: "联系电话",
          placeholder: "请输入联系电话",
          lableWidth: "70px",
        },
        { slotName: "operationSelect" },
        { slotName: "productId" },
        {
          key: "entryTime",
          type: "pickerOptions",
          labelName: "添加时间",
          clearable: true,
          lableWidth: "50",
          placeholder: "请选择时间",
          valueFormat: "timestamp",
          pickerOptions: {
            onPick: ({ maxDate, minDate }) => {
              if (minDate && this.pickerMinDate) {
                this.pickerMinDate = null;
              } else if (minDate) {
                this.pickerMinDate = minDate.getTime();
              }
            },
            disabledDate: (time) => {
              if (this.pickerMinDate) {
                return (
                  time.getTime() > this.pickerMinDate + this.day31 ||
                  time.getTime() < this.pickerMinDate - this.day31
                );
              }
              return false;
            },
          },
        },
        { slotName: "AddSlot" },
      ],
    };
  },
  created() {
    this.handleConfirm(this.initData)
    this.getAllMachineType();
  },
  watch: {
    downloadinitData: {
      handler(newValue, oldValue) {
        // 处理对象变化
        console.log(newValue);
      },
      deep: true,
    },
  },
  methods: {
    //回收商名称下拉
    getAllMachineType() {
      _api
        .listSimpleMerchantView({
          isFilterMatrix: "01",
          isNice: "",
          pageNum: 1,
          pageSize: 999999,
        })
        .then((res) => {
          this.machineType = res.data;
        });
    },
    switchTrigin(type) {
      this.rankType = type;
      this.redenvellistFn();
    },
    dataObtain(way) {
      this.dateType = way;
      this.downloadinitData.entryTime = "";
      this.redenvellistFn();
    },
    addOneMenu() {
      this.csetionShow = true;
    },
    handleConfirm(data,cd) {
      console.log(data, this.userAccount);
      let SeachParams = {};
      let startTime = "";
      let endTime = "";
      if (data.entryTime === null) {
        startTime = "";
        endTime = "";
      } else {
        startTime = moment(data.entryTime[0]).format("x");
        endTime = moment(data.entryTime[1]).format("x");
      }
      SeachParams = {
        contactName: data && data.contactName ? data.contactName : "",
        contactPhone: data && data.contactPhone ? data.contactPhone : "",
        contractState: "",
        effectiveStatus: this.effectiveStatus,
        financeRatio: this.financeRatio,
        merchantId: this.merchantId,
        startTime: startTime,
        endTime: endTime,
        pageNum: 1,
        pageSize: 10,
        disuseLoding: () => {
          if(typeof cd ==='function'){
             cd();
          }
        },
      };
      console.log(SeachParams);
      this.$store.commit("tagsView/SEACH_PARAMS", SeachParams);
    },
    selecthandleChange(e) {
      this.iid = e;
    },
  },
};
</script>

<style lang="scss" scoped>
.global-form {
  /deep/.el-input__inner {
    width: 160px;
  }
}

.tip {
  color: #ff687b;
}

.time-select {
  display: flex;
  margin-left: 20px;

  .btn {
    box-shadow: 2px 1px 10px 0px rgba(63, 44, 245, 0.2);
    margin: 0px 10px 0px 0;
    text-align: center;
    cursor: pointer;
  }

  .btnType-after2 {
    // width: 33%;
    width: 80px;
    min-width: max-content;
    height: 30px;
    background: #ffffff;
    color: #0981ff;
    line-height: 30px;
    border-radius: 20px;
  }

  .btnType2 {
    width: 80px;
    min-width: max-content;
    height: 30px;
    background: #0981ff;
    color: white;
    line-height: 30px;
    border-radius: 20px;
  }
}

.andleShowbox {
  border: 1px solid #0981ff;
  width: 200px;
  display: flex;
  justify-content: space-between;
  border-radius: 4px;

  .andleShowstyle {
    width: 100px;
    height: 30px;
    line-height: 30px;
    text-align: center;
    color: #0981ff;
    cursor: pointer;
    user-select: none;
  }

  .andleShowcolor {
    background-color: #0981ff;
    color: #fff;
  }
}
</style>
